import React from "react";
import activity_Jul07 from "../../data/0707.json";
import activity_Jul08 from "../../data/0708.json";
import activity_Jul09 from "../../data/0709.json";
import activity_Jul11 from "../../data/0711.json";
import activity_Jul12 from "../../data/0712.json";
import activity_Jul13 from "../../data/0713.json";
import activity_Jul14 from "../../data/0714.json";
import activity_Jul15 from "../../data/0715.json";
import activity_Jul16 from "../../data/0716.json";
import activity_Jul17 from "../../data/0717.json";
import activity_Jul18 from "../../data/0718.json";
import activity_Jul19 from "../../data/0719.json";
import Section from "../global/Section";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

export default function List() {
  return (
    <Section>
      <Tabs>
        <TabList>
          <Tab>Stability Master 07/19</Tab>
          <Tab>Stability Master 07/18</Tab>
          <Tab>Stability Master 07/17</Tab>
          <Tab>Stability Master 07/16</Tab>
          <Tab>Stability Master 07/15</Tab>
          <Tab>Stability Master 07/14</Tab>
          <Tab>Stability Master 07/13</Tab>
          <Tab>Stability Master 07/12</Tab>
          <Tab>Stability Master 07/11</Tab>
          <Tab>Stability Master 07/10 (Skipped)</Tab>
          <Tab>Stability Master 07/09</Tab>
          <Tab>Stability Master 07/08</Tab>
        </TabList>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul19.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul18.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul17.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul16.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul15.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul14.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul13.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul12.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul11.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          The testnet was down for the whole duration of 07/10 due to maintenance. For compensation, we are giving out
          2x bonus for 07/11.
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul09.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul08.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Rank</th>
                      <th scope="col">ETH Address</th>
                      <th scope="col">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activity_Jul07.map((el, index) => {
                      return (
                        <tr key={index}>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {index}
                          </th>
                          <th scope="row" className="content-medium-title font-w-bold">
                            {el[0]}
                          </th>
                          <td className="content-medium-title font-w-bold">{el[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </TabPanel>
      </Tabs>
    </Section>
  );
}
