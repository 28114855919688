import React from "react";

import PropTypes from "prop-types";
import {main, mainContent} from "./style/layout.module.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

// import "bootstrap/dist/css/bootstrap.min.css";
import * as styles from "bootstrap/dist/css/bootstrap.min.css"
// import "../components/style/main.scss";
import * as style1 from "../components/style/main.scss"


const Layout = ({ children }) => {
  return (
    <div className={main}>
      {/* <Navbar /> */}
      <div className={mainContent}>
        
        <main>{children}</main>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

library.add(fab);

export default Layout;
