import React, { Component } from 'react'
import Layout from "../components/layout";
import Hero from "../components/leaderBoard/Hero"
import List from "../components/leaderBoard/List"

export default class leaderboard extends Component {
    render() {
        return (
            <Layout>
                <Hero/>
                <List />
            </Layout>
        )
    }
}
